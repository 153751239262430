export const initialComponents = (initial) => {
  return {
    street_number: initial.street_number || "",
    route: initial.route || "",
    postcode: initial.postcode || "",
    neighborhood: initial.neighborhood || "",
    locality: initial.locality || "",
    place: initial.place || "",
    district: initial.district || "",
    region: initial.region || "",
    country: initial.country || "",
    latitude: initial.latitude || "",
    longitude: initial.longitude || "",
  };
};
