import { isSameDay, isToday, isYesterday, format } from "date-fns";

export default (singleMessage, previousMessage, userId) => ({
  message: singleMessage,
  previousMessage,
  isSender: userId === singleMessage.sender.id,
  sentToday: isToday(new Date(singleMessage.created_at)),
  sentYesterday: isYesterday(new Date(singleMessage.created_at)),
  isSameDayAsPrevious: previousMessage
    ? isSameDay(
        new Date(singleMessage.created_at),
        new Date(previousMessage.created_at),
      )
    : false,
  isSameSenderAsPrevious: previousMessage
    ? singleMessage.sender.id === previousMessage.sender.id
    : false,

  isSameDay,

  format,

  init() {
    this.$watch("singleMessage", (updatedMessage) => {
      this.message = updatedMessage;
    });
  },
});
