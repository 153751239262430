const intlSupported = window.Intl && typeof window.Intl === "object";

export default (withTrigger = false) => ({
  metadata: intlSupported
    ? btoa(Intl.DateTimeFormat().resolvedOptions().timeZone)
    : null,
  trigger: false,

  onPaste() {
    this.trigger = withTrigger && true;
  },
});
