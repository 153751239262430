export default async function (query) {
  if (query.length < 3) {
    return [];
  }

  const response = await fetch(`/api/locations?q=${query}`, {
    cache: "force-cache",
  }).catch((error) => {
    console.error("Error fetching locations", error);
    return [];
  });

  try {
    const json = await response.json();

    return json.data;
  } catch (error) {
    console.error("Error parsing JSON", response, error);

    return [];
  }
}
