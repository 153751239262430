import geocoder from "../geocoder";
import { debounce } from "lodash-es";

export default () => ({
  query: "",
  selected: null,
  locations: [],
  loading: false,

  init() {
    const debouncedGeocode = debounce(async (query) => {
      this.loading = true;

      const geocode = geocoder(false);

      this.locations = await geocode(query, {
        includeAddresses: false,
        includeStates: true,
      });

      this.loading = false;
    }, 750);

    this.$watch("query", (query) => {
      debouncedGeocode(query);
    });

    this.$watch("selected", (selected) => {
      this.$el.closest("form").submit();
    });
  },
});
