export default (placement = "bottom-start") => ({
  open: false,

  button: {
    ["@click"]() {
      this.toggle();
    },

    ["x-bind:aria-expanded"]() {
      return this.open;
    },
  },

  panel: {
    ["x-show"]() {
      return this.open;
    },

    ["x-on:click.outside"]() {
      this.close(this.$refs.button);
    },

    [`x-anchor.${placement}`]: "$refs.button",
  },

  toggle() {
    if (this.open) {
      return this.close();
    }

    this.$refs.button.focus();

    this.open = true;
  },

  close(focusAfter) {
    if (!this.open) {
      return;
    }

    this.open = false;

    focusAfter && focusAfter.focus();
  },
});
