import { CSRF_TOKEN } from "../constants.js";

export default ({ messagesUrl, userId, id }) => ({
  loading: false,
  messages: [],
  nextUrl: messagesUrl,
  hasMore: false,
  showBackToBottom: false,
  userId,

  init() {
    Echo.private(`App.Models.Conversation.${id}`)

      .listen("Conversations\\MessageDelivered", async (event) => {
        this.upsertMessage(event);

        if (event.sender.id === this.userId) {
          return;
        }

        fetch(`/inbox/${id}/participants`, {
          method: "PUT",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "X-CSRF-TOKEN": CSRF_TOKEN,
            "X-Socket-Id": Echo.socketId(),
          },
        }).catch((error) => console.error(error));
      })

      .listen("Conversations\\ConversationSeen", async (event) => {
        const lastMessagesResponse = await fetch(`/inbox/${id}/messages`, {
          method: "GET",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "X-Socket-Id": Echo.socketId(),
          },
        }).catch((error) =>
          console.error(
            `Fetch error for conversation ${id} with user ${userId}:`,
            error,
          ),
        );

        if (!lastMessagesResponse || !lastMessagesResponse.ok) {
          console.error(
            `Fetch failed or returned an invalid response for conversation ${id} with user ${userId}. Status: ${lastMessagesResponse?.status} - ${lastMessagesResponse?.statusText}`,
          );
          return;
        }

        try {
          const json = await lastMessagesResponse.json();

          const updatedMessages = json.data;

          updatedMessages.forEach((message) => {
            this.upsertMessage(message);
          });
        } catch (jsonError) {
          console.error(
            `Failed to parse JSON for conversation ${id} with user ${userId}. Response:`,
            lastMessagesResponse,
          );
        }
      });
  },

  upsertMessage(message) {
    const index = this.messages.findIndex((m) => m.id === message.id);

    if (index === -1) {
      this.messages.push(message);
    } else {
      this.messages[index] = message;
    }
  },

  async loadMoreMessages() {
    if (!this.nextUrl) {
      return;
    }

    this.loading = true;

    try {
      const response = await fetch(this.nextUrl, {
        method: "GET",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      const json = await response.json();

      this.nextUrl = json.links.next;

      this.hasMore = !!this.nextUrl;

      this.messages.unshift(...json.data.reverse());
    } catch (error) {
      console.error(error);

      alert(
        `Something has gone wrong, please try again later or contact support.`,
      );
    } finally {
      this.loading = false;
    }
  },
});
