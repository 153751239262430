<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M1.5 20.5c-.55228 0-1-.44772-1-1v-18c0-.55228.44772-1 1-1h18c.55228 0 1 .44772 1 1v18c0 .55228-.44772 1-1 1z"
      />
      <path
        d="M3.5 22.5v2e-7c0 .552285.447715 1 1 1h18c.552285 0 1-.447715 1-1v-18c0-.552285-.447715-1-1-1"
      />
      <path
        d="M5.5 8.308V15c0 .276142.223858.5.5.5h8c.276142 0 .5-.223858.5-.5V8.308"
      />
      <path
        d="M3.5 10l5.854-4.953c.372866-.31555.919134-.31555 1.292 0L16.5 10M11.5 15.5h-3v-3 2e-7c-1e-7-.828427.671573-1.5 1.5-1.5.828427-1e-7 1.5.671573 1.5 1.5z"
      />
    </g>
  </svg>
</template>
