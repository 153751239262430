<template>
  <div>
    <input type="hidden" :name="name('mapbox_id')" :value="location.id" />
    <input
      type="hidden"
      :name="name('place_name')"
      :value="location.place_name"
    />
    <input
      type="hidden"
      :name="name('street_number')"
      :value="location.street_number"
    />
    <input type="hidden" :name="name('route')" :value="location.route" />
    <input type="hidden" :name="name('postcode')" :value="location.postcode" />
    <input
      type="hidden"
      :name="name('neighborhood')"
      :value="location.neighborhood"
    />
    <input type="hidden" :name="name('locality')" :value="location.locality" />
    <input type="hidden" :name="name('place')" :value="location.place" />
    <input type="hidden" :name="name('district')" :value="location.district" />
    <input type="hidden" :name="name('region')" :value="location.region" />
    <input
      type="hidden"
      :name="name('country')"
      :value="location.country || country"
    />
    <input type="hidden" :name="name('latitude')" :value="location.latitude" />
    <input
      type="hidden"
      :name="name('longitude')"
      :value="location.longitude"
    />
  </div>
</template>

<script>
export default {
  props: ["location", "country", "template"],

  methods: {
    name(name) {
      return this.template ? this.template(name) : name;
    },
  },
};
</script>
