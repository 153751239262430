export const USER_ID = document.querySelector('meta[name="user-id"]').content;
export const GEO_REGION = document.querySelector(
  'meta[name="geo-region"]',
).content;

export const CSRF_TOKEN = document.querySelector(
  'meta[name="csrf-token"]',
).content;
export const PUSHER_APP_KEY = document.querySelector(
  'meta[name="pusher-app-key"]',
).content;
export const STRIPE_KEY = document.querySelector(
  'meta[name="stripe-key"]',
).content;

export const CLOUDINARY_CLOUD = import.meta.env.VITE_CLOUDINARY_CLOUD;
export const CLOUDINARY_UPLOAD_PRESET = import.meta.env
  .VITE_CLOUDINARY_UPLOAD_PRESET;

export const MAPBOX_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export const AWS_LOCATION_KEY = import.meta.env.VITE_AWS_LOCATION_KEY;
