<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke-linecap="round"
      fill="none"
      stroke-linejoin="round"
      class="stroke-current"
    >
      <path
        d="M12.5 14a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h4c.28-.56.451-1 1.118-1h2.264c.667 0 .838.44 1.118 1h1.5a1 1 0 0 1 1 1z"
      />
      <path d="M8.914 9.086a2 2 0 1 1-2.828 2.828 2 2 0 0 1 2.828-2.828" />
      <path
        d="M4.167 17A5.387 5.387 0 0 0 1.5 18.943 10.135 10.135 0 0 0 .5 23h23a10.135 10.135 0 0 0-1-4.057c-.7-1.41-3.65-2.387-7.347-3.756-1-.371-.836-2.981-.393-3.468C15.8 10.576 16.68 9.235 16.68 6c0-3.265-2.13-5-4.68-5a4.416 4.416 0 0 0-4.351 3"
      />
    </g>
  </svg>
</template>
