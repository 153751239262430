<template>
  <dropzone
    multiple
    :location="`/rooms/${roomId}/photos`"
    :redirect="redirectLocation"
    :on-loading="isLoading"
    :max-files="maxFiles"
  >
    <div
      v-if="loading"
      class="flex h-full items-center justify-center py-4 text-teal"
    >
      <LoadingIndicator />
    </div>
    <slot v-else>
      <div class="flex h-64 flex-col items-center justify-center text-teal">
        <IconProfilePhoto class="h-16 w-16" />

        <div class="mt-3 rounded font-bold underline-teal">
          Upload room photos
        </div>
      </div>
    </slot>
  </dropzone>
</template>

<script>
export default {
  props: ["roomId", "redirect", "maxFiles"],

  data() {
    return {
      loading: false,
      redirectLocation: this.redirect || `/rooms/${this.roomId}`,
    };
  },

  methods: {
    isLoading() {
      this.loading = true;
    },
  },
};
</script>
