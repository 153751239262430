import "./bootstrap";

import { createApp, onMounted } from "vue";

const app = createApp({
  setup() {
    onMounted(() => {
      document.querySelector("[autofocus]")?.focus();
    });
  },
});

const components = import.meta.glob("./components/**/*.vue", { eager: true });

Object.entries(components).forEach(([path, definition]) => {
  app.component(path.split("/").pop().split(".")[0], definition.default);
});

app.config.compilerOptions.whitespace = "preserve";

app.mount("#app");
