<template>
  <span
    class="mb-1 mr-1 inline-flex overflow-hidden rounded-lg bg-teal text-white"
  >
    <span class="inline-block px-3 py-1 text-sm">{{ title }}</span>
    <span class="bg-teal-dark">
      <button
        type="button"
        v-on:click.prevent="onRemove(location)"
        class="inline-block h-full px-3 text-white"
      >
        <icon-remove class="h-2 w-2" />
      </button>
    </span>

    <LocationFields :template="template(index)" :location="location">
    </LocationFields>
  </span>
</template>

<script>
export default {
  props: ["location", "index", "onRemove"],

  methods: {
    template(index) {
      return (name) => `locations[${index}][${name}]`;
    },
  },

  computed: {
    title() {
      return [
        this.location.neighborhood,
        this.location.place,
        this.location.region,
      ]
        .filter((x) => x)
        .join(", ");
    },
  },
};
</script>
