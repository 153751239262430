import Echo from "laravel-echo";

import Pusher from "pusher-js";
window.Pusher = Pusher;

import { USER_ID, PUSHER_APP_KEY } from "./constants";

if (USER_ID) {
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: PUSHER_APP_KEY,
    cluster: "mt1",
    forceTLS: true,
  });
}
