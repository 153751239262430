import { GEO_REGION } from "./constants";
import roomies from "./api/roomies";
import mapbox from "./api/mapbox";

export default (includeAddresses) => {
  if (GEO_REGION === "SG" && !includeAddresses) {
    return roomies;
  }

  return mapbox;
};
