<template>
  <li
    :class="[
      'min-w-auto relative flex cursor-default select-none items-center px-3 py-3',
      active ? 'bg-orange-50' : 'text-gray-800',
    ]"
  >
    <div
      class="mr-3 inline-block"
      :class="[active ? 'text-orange-500' : 'text-gray-300']"
    >
      <IconPinSpecial class="h-5 w-5" v-if="result.type === 'region'" />
      <IconPinPoint class="h-5 w-5" v-else />
    </div>

    <div v-if="includeAddresses">
      <div>{{ result.place_name }}</div>
      <div class="text-xs">{{ result.region }}</div>
    </div>

    <div
      v-else-if="
        noStates && (result.type === 'region' || result.type === 'district')
      "
    >
      <div>{{ result.place_name }}</div>
      <div class="text-xs">Region</div>
    </div>

    <div v-else-if="result.type === 'region' || result.type === 'district'">
      <div>{{ result.region }}</div>
      <div class="text-xs">State</div>
    </div>

    <div v-else-if="result.address">
      {{ result.street_number }} {{ result.address }}, {{ result.place }},
      <div class="text-xs">{{ result.region }}</div>
    </div>

    <div v-else>
      <span>{{ formattedResult }}</span>
      <div class="text-sm">{{ result.region }}</div>
    </div>
  </li>
</template>

<script>
import { GEO_REGION } from "../../constants";

export default {
  props: ["result", "active", "includeAddresses"],

  computed: {
    formattedResult() {
      return [this.result.neighborhood, this.result.place]
        .filter((x) => x)
        .join(", ");
    },

    noStates() {
      return ["IE"].includes(GEO_REGION);
    },
  },
};
</script>
