import "maplibre-gl/dist/maplibre-gl.css";
import { AWS_LOCATION_KEY } from "./../constants";

const mapName = "roomies-properties";
const region = "us-east-1";

export default ({ latitude, longitude }) => ({
  async render() {
    const maplibregl = await import("maplibre-gl");
    const center = [longitude, latitude];

    if (!this.$refs.map) {
      return;
    }

    const map = new maplibregl.Map({
      container: this.$refs.map,
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${AWS_LOCATION_KEY}`,
      center: center,
      zoom: 13,
    });

    map.addControl(new maplibregl.NavigationControl());

    map.on("load", () => {
      this.addSource(map, center);
      this.addLayer(map);
    });
  },

  addSource(map, center) {
    map.addSource("circleData", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
          },
        ],
      },
    });
  },

  addLayer(map) {
    map.addLayer({
      id: "data",
      type: "circle",
      source: "circleData",
      paint: {
        "circle-radius": {
          stops: [
            [10, 10],
            [12, 50],
            [16, 150],
          ],
        },
        "circle-color": "#F2545B",
        "circle-opacity": 0.5,
      },
    });
  },
});
