<template>
  <form
    class="cursor-pointer"
    :class="{ 'bg-gray-500': dragging }"
    @click="onClick"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @drop.prevent.stop="onDrop"
    @dragover.prevent.stop
  >
    <div class="pointer-events-none">
      <slot></slot>
      <input
        ref="input"
        type="file"
        accept="image/*"
        :multiple="multiple"
        @change="onChange"
        class="hidden"
      />
    </div>
  </form>
</template>

<script>
import { CSRF_TOKEN } from "../constants";
import { upload } from "../api/cloudinary";

export default {
  props: {
    method: {
      type: String,
      default: "POST",
    },
    location: String,
    redirect: String,
    multiple: Boolean,
    onLoading: Function,
    maxFiles: Number,
  },

  data() {
    return {
      dragging: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.input.click();
    },

    onDragEnter() {
      this.dragging = true;
    },

    onDragLeave(event) {
      this.dragging = false;
    },

    onDrop(event) {
      this.handleFiles(event.dataTransfer.files);
    },

    onChange(event) {
      this.handleFiles(event.target.files);
    },

    async handleFiles(files) {
      if (this.maxFiles && files.length > this.maxFiles) {
        alert(
          `You can only upload ${this.maxFiles} more photo(s) for this listing.`,
        );
        this.dragging = false;
        return;
      }

      this.onLoading(true);

      const uploads = Array.from(files).map((file) => this.uploadFile(file));

      try {
        await Promise.all(uploads);

        this.onLoading(false);

        this.onSuccess();
      } catch (error) {
        console.error(error);

        this.onLoading(false);

        alert(
          `Something has gone wrong with the upload, please try again later or contact support.`,
        );
      }
    },

    onSuccess() {
      if (this.redirect) {
        window.location.href = this.redirect;
        return;
      }

      location.reload(true);
    },

    async uploadFile(file) {
      const response = await upload(file);

      const json = await response.json();

      return fetch(this.location, {
        headers: {
          "X-CSRF-TOKEN": CSRF_TOKEN,
          "Content-Type": "application/json",
        },
        method: this.method,
        body: JSON.stringify({
          public_id: json.public_id,
          perception_hash: json.phash,
        }),
      }).catch((error) => {
        console.error(error);
        alert(
          "Something has gone wrong, please try again later or contact support.",
        );
      });
    },
  },
};
</script>
