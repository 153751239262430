<template>
  <div>
    <MapboxAutocomplete
      include-addresses
      id="address"
      :initial="initial"
      :placeholder="initial.place_name || 'Search your address...'"
      :select="onChange"
    >
    </MapboxAutocomplete>

    <LocationFields :country="country" :location="location"> </LocationFields>
  </div>
</template>

<script>
import { initialComponents } from "../../helpers";

export default {
  props: ["initial", "country"],

  data() {
    return {
      location: initialComponents(this.initial),
    };
  },

  methods: {
    onChange(location) {
      this.location = { ...location };
    },
  },
};
</script>
