<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M14.3 12.179c-.0977544-.0975076-.0979544-.255799-.0004469-.353553a.2503867.2503867 0 01.000447-.000447l9.263-9.262c.585698-.586526.585026-1.5368-.0015002-2.1225-.586526-.585698-1.5368-.585026-2.1225.0015002l-9.262 9.258c-.0975076.0977544-.255799.0979545-.353553.0004469A.2503867.2503867 0 0111.823 9.7l-9.262-9.258v1e-7c-.585698-.585974-1.53553-.586198-2.1215-.0005-.585974.585698-.586198 1.53553-.0005 2.1215l9.261 9.262c.0977544.0975075.0979545.255799.0004469.353553A.2503867.2503867 0 019.7 12.179L.439 21.442l-1e-7 2e-7c-.585698.585974-.585474 1.5358.0005002 2.1215.585974.585698 1.5358.585474 2.1215-.0005003l9.262-9.263c.0975076-.0977544.255799-.0979545.353553-.0004469a.2503867.2503867 0 01.000447.0004469l9.262 9.263-2e-7-1e-7c.585698.585974 1.53553.586198 2.1215.0005002.585974-.585698.586198-1.53553.0005002-2.1215z"
    />
  </svg>
</template>
