import { CLOUDINARY_CLOUD, CLOUDINARY_UPLOAD_PRESET } from "../constants";

export const upload = (file) => {
  const formData = new FormData();
  formData.set("file", file);
  formData.set("upload_preset", CLOUDINARY_UPLOAD_PRESET);

  try {
    return fetch(`https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD}/upload`, {
      method: "POST",
      body: formData,
    });
  } catch (error) {
    console.error("Error uploading image", error);

    return null;
  }
};
