<template>
  <div>
    <div v-if="!authenticated">
      <div class="mb-8">
        <h2 class="text-2xl">Create an account or login</h2>
      </div>

      <div class="mb-4">
        <label class="form-label" for="first_name">First name</label>
        <input
          type="text"
          name="first_name"
          id="first_name"
          v-model="firstName"
          class="form-control"
          required
          autocomplete="name"
        />
      </div>

      <div class="mb-4">
        <label class="form-label" for="email">Email</label>

        <input
          type="email"
          name="email"
          class="form-control"
          v-model="email"
          @focus="onEmailFocus"
          required
          autocomplete="email"
        />

        <p class="form-error" role="alert" v-if="errorMessage">
          {{ errorMessage }}
        </p>
      </div>

      <div class="mb-4" v-if="showPasswordField">
        <label class="form-label" for="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          v-model="password"
          class="form-control"
          required
          autocomplete="new-password"
        />
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-block btn-lg btn-teal"
      ref="submit"
      @click="submit"
    >
      {{ value }}
    </button>
  </div>
</template>

<script>
import { CSRF_TOKEN } from "../constants";

export default {
  props: {
    initialAuthenticated: Boolean,
    value: String,
  },

  data() {
    return {
      authenticated: this.initialAuthenticated,
      firstName: "",
      email: "",
      password: "",
      errorMessage: null,
      showPasswordField: false,
    };
  },

  methods: {
    onEmailFocus() {
      this.showPasswordField = true;
    },

    async attemptAuthentication() {
      const response = await fetch("/authentication", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          first_name: this.firstName,
          email: this.email,
          password: this.password,
        }),
      }).catch((error) => console.error(error));

      try {
        const json = await response.json();

        if (!response.ok) {
          // Get the first error message for the first field.
          const key = Object.keys(json.errors)[0];
          this.errorMessage = json.errors[key][0];

          return;
        }

        if (json.two_factor) {
          window.location.pathname = "/two-factor-challenge";

          return;
        }

        Array.from(document.querySelectorAll("input[name=_token]")).map(
          (input) => (input.value = json.token),
        );

        this.authenticated = true;

        this.$refs.submit.click();
      } catch (e) {
        console.error("Authentication failed", e, response);
      }
    },

    submit(event) {
      if (this.authenticated) {
        return;
      }

      const form = event.target.closest("form");
      if (!form.reportValidity()) {
        return;
      }

      event.preventDefault();
      this.attemptAuthentication();
    },
  },
};
</script>
