<template>
  <dropzone
    method="PUT"
    location="/photo"
    :redirect="redirect"
    :on-loading="isLoading"
    :class="classes"
  >
    <div v-if="loading" class="flex h-full items-center justify-center">
      <LoadingIndicator />
    </div>

    <slot v-else>
      <div class="p-4">
        <IconRoomPhotos />
      </div>
    </slot>
  </dropzone>
</template>

<script>
export default {
  props: {
    unstyled: Boolean,
    redirect: String,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    classes() {
      if (!this.unstyled) {
        return "bg-white h-16 w-16 rounded-full";
      }
    },
  },

  methods: {
    isLoading() {
      this.loading = true;
    },
  },
};
</script>
