<template>
  <div class="space-y-3">
    <MapboxAutocomplete
      id="address"
      :select="onSelect"
      :clear-after-selection="true"
      :disabled="locations.length === maximumLocations"
    ></MapboxAutocomplete>

    <div class="form-label" v-if="locations.length">You've selected...</div>

    <ProfileLocation
      v-for="(location, index) in locations"
      :key="index"
      :index="index"
      :location="location"
      :on-remove="onRemove"
    ></ProfileLocation>

    <div
      class="form-error"
      role="alert"
      v-if="locations.length === maximumLocations"
    >
      You've reached the maximum number of locations.
    </div>

    <div class="form-error" role="alert" v-if="multipleRegions">
      Are you sure that you're looking in more than 1 state?
    </div>
  </div>
</template>

<script>
const MAXIMUM_LOCATIONS = 10;

export default {
  props: ["initial"],

  data() {
    return {
      locations: this.initial || [],
      maximumLocations: MAXIMUM_LOCATIONS,
    };
  },

  methods: {
    onSelect(location) {
      this.locations.push(location);
    },

    onRemove(location) {
      const index = this.locations.indexOf(location);
      this.locations.splice(index, 1);
    },
  },

  computed: {
    multipleRegions() {
      return (
        new Set(this.locations.map((location) => location.region)).size > 1
      );
    },
  },
};
</script>
